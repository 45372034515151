.MuiTab-root{
  position: relative;

  .tab_notification{
    position: absolute;

    right:3px;
    top:3px;

    background: red;
    border-radius: 50%;

    width:10px;
    height:10px;

  }
}